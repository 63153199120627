'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const retOrCall = (value) => {
    if (value === undefined) {
        return value;
    }
    return value instanceof Function ? value() : value;
};
const objSwitchToCases = (obj) => {
    if (Object.keys(obj).length === 0) {
        return [];
    }
    const cases = [];
    let def;
    for (const k in obj) {
        if (obj.hasOwnProperty(k)) {
            if (k === 'default') {
                def = obj[k];
            }
            else {
                cases.push({
                    key: k,
                    val: obj[k],
                });
            }
        }
    }
    if (def !== undefined) {
        cases.push({
            key: 'default',
            val: def,
        });
    }
    return cases;
};
const arrToCases = (arr) => {
    const cases = [];
    for (let i = 0; i < arr.length - 1; i += 2) {
        cases.push({
            key: arr[i],
            val: arr[i + 1],
        });
    }
    // if the last argument is alone, it's considered the default:
    if (arr.length % 2) {
        cases.push({
            key: 'default',
            val: arr[arr.length - 1],
        });
    }
    return cases;
};

const sw = function (condition, arr) {
    const switches = Array.isArray(arr) ? arrToCases(arr) : objSwitchToCases(arr);
    const cond = retOrCall(condition);
    let cs = switches.find((x) => {
        return retOrCall(x.key) === cond;
    });
    if (cs === undefined) {
        cs = switches.find((x) => retOrCall(x.key) === 'default');
    }
    return cs ? retOrCall(cs.val) : undefined;
};

const ternary = function (cond, a, b) {
    return retOrCall(cond) ? retOrCall(a) : retOrCall(b);
};

const createRe = (carry, cond, val) => {
    const f = function () {
        return carry ? carry : retOrCall(cond) ? retOrCall(val) : undefined;
    };
    const z = new Re(retOrCall(cond) ? retOrCall(val) : carry);
    Object.assign(f, z);
    Object.setPrototypeOf(f, Re.prototype);
    return f;
};
class Re {
    carry;
    constructor(carry) {
        this.carry = carry;
    }
    else(val) {
        return createRe(this.carry, true, val);
    }
    elseif(cond, val) {
        return createRe(this.carry, cond, val);
    }
}
const fi = (cond, val) => {
    return createRe(undefined, cond, val);
};

exports.fi = fi;
exports.sw = sw;
exports.ternary = ternary;
